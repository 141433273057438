/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ActionButton,
  ActionsGroup,
  Alert,
  Col,
  Container,
  FAB,
  Icon,
  ObjectUtils,
  Panel,
  ProtectedComponent,
  Row,
  SearchPagination,
  SearchPanel,
  Table,
  usePagedQuery
} from '@elotech/components';
import { ClienteService } from 'common/service';
import { Cliente } from 'common/type';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Roles } from '../../roles/RolesConsole';

const initFilter = (searchParam: string, field: string) => {
  const searchParams = new URLSearchParams(searchParam);

  const search = searchParams.get(field);

  return search;
};

const ClienteListPage = () => {
  const history = useHistory();
  const [filter, setFilter] = useState(
    initFilter(history.location.search, 'search') ?? ''
  );
  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<Cliente>({
    search: ClienteService.search,
    onError: error =>
      Alert.error({ title: 'Erro ao buscar os clientes' }, error)
  });

  useEffect(() => {
    doSearch(filter);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFilter = () => {
    doSearch(filter);
    history.replace(`${history.location.pathname}?search=${filter}`);
  };

  return (
    <Container breadcrumb>
      <Panel isTable>
        <SearchPanel>
          <Row className="mb-xs">
            <Col className="form-group">
              <input
                autoFocus
                value={filter}
                name="searchValue"
                placeholder="Digite o filtro"
                onChange={e => setFilter(e.target.value)}
                style={{ width: `calc(100% - 82px)`, height: '28px' }}
                onKeyDown={e => e.key === 'Enter' && onFilter()}
              />
              <button
                data-test-id="buttonAdicionarFiltro"
                className="btn filter"
                type="button"
                onClick={onFilter}
              >
                Filtrar
              </button>
            </Col>
          </Row>
        </SearchPanel>
        <Table
          values={values}
          loading={loading}
          keyExtractor={(cliente: Cliente) => `${cliente.id}`}
        >
          <Table.Column<Cliente>
            header="Nome"
            value={cliente => cliente.nome}
          />
          <Table.Column<Cliente>
            header="Tenant"
            value={cliente => (
              <div>
                <a
                  href={
                    window.location.origin?.replace(
                      'console',
                      cliente.tenant
                    ) || '#'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{cliente.tenant}</span>
                  &nbsp;
                  <Icon
                    icon="external-link-alt"
                    darkPrimary
                    hoverColor="white"
                  />
                </a>
              </div>
            )}
          />
          <Table.Column<Cliente> header="UF" value={cliente => cliente.uf} />
          <Table.Column<Cliente>
            header="Ativo"
            value={cliente => ObjectUtils.booleanToSimNao(cliente.ativo)}
          />
          <Table.Column<Cliente>
            header="On-Premise"
            value={cliente => ObjectUtils.booleanToSimNao(cliente.onPremise)}
          />
          <Table.Column<Cliente>
            header="Multi-Realm"
            value={cliente => ObjectUtils.booleanToSimNao(cliente.multiRealm)}
          />
          <Table.Column<Cliente>
            header="Contratante"
            value={cliente => cliente.contratante}
          />
          <Table.Column<Cliente>
            header=""
            value={cliente => (
              <ActionsGroup>
                <ActionButton
                  key="view-button"
                  icon="eye"
                  label="Visualizar"
                  path={`/clientes/${cliente.id}/view`}
                />
                <ProtectedComponent role={Roles.clientes_modulo_config.name}>
                  <ActionButton
                    key="config-button"
                    icon="cog"
                    label="Configuração dos Módulos"
                    path={`/clientes/${cliente.id}/modulo-config`}
                  />
                </ProtectedComponent>
                <ProtectedComponent role={Roles.manage_cliente.name}>
                  <ActionButton
                    key="edit-button"
                    icon="pencil-alt"
                    label="Editar"
                    path={`/clientes/${cliente.id}`}
                  />
                </ProtectedComponent>
              </ActionsGroup>
            )}
          />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
      <ProtectedComponent role={Roles.manage_cliente.name}>
        <div className="btn-save">
          <FAB
            data-test-id="btn-new"
            icon="plus"
            path="/clientes/novo"
            title="Novo cliente"
          />
        </div>
      </ProtectedComponent>
    </Container>
  );
};

export default ClienteListPage;
